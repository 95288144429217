<template>
    <div class="public-profile-layout-container" style="max-width: 600px">
        <div class="public-profile-page">
            <div class="public-profile-card row-start mb-20 mb-20">
                <div class="public-cover-photo" v-if="manufacturerItem?.cover">
                    <div class="public-cover-photo-container">
                        <img :src="manufacturerItem.cover"/>
                    </div>
                </div>
                <div class="public-profile-card-body public-profile-card_with-cover row-start lg-12 nb-8 md-9">
                    <div class="preview-manufacturer__info w-100" v-if="manufacturerItem">
                        <p class="f-18-black f-bold">{{ manufacturerItem?.name }}</p>
                        <p>{{ manufacturerItem?.model }}</p>
                        <p>{{ manufacturerItem?.serial_number }}</p>
                    </div>
                    <div class="row-start mb-2"
                         v-for="btn in manufacturerItem?.buttons?.sort((a, b) => a?.position - b?.position)">
                        <div class="column-start" style="flex: 1">
                            <button
                                @click="btnHandler(btn)"
                                draggable="true"
                                class="primary-button manufacturer-items__buttons-item w-100"
                                :style="{backgroundColor: btn.data.bgColor, color: btn?.data?.txtColor}"
                            >
                                <div
                                    v-if="btn?.type === TYPE_FIELDS.phone"
                                    class="phone-icon-container"
                                    :style="{borderColor: btn?.data?.txtColor}"
                                >
                                    <phone-icon :style="{fill: btn?.data?.txtColor}"/>
                                </div>
                                <arrow-shewron
                                    v-if="btn.type === TYPE_FIELDS.contentButton"
                                    class="i-green mr-1"
                                    :style="{fill: btn?.data?.txtColor}"
                                    :transform="showContent?.id === btn?.id ? '-90' : '90'"
                                />
                                {{ btn.name }}
                            </button>
                            <transition name="fade-content">
                                <div
                                    :class="['w-100 content-preview pt-5', {'content-preview__show' : showContent?.id === btn?.id}]"
                                    v-show="showContent?.id === btn?.id"
                                >
                                    <loader v-if="isLoading"/>
                                    <ManufacturerContentSection
                                        v-else-if="buttonContent && Array.isArray(buttonContent) && buttonContent?.length"
                                        :content="buttonContent"
                                    />
                                    <div v-else-if="!isLoading">No content</div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ACTION_ENTITY_TYPES, ACTION_TYPES, TYPE_FIELDS} from "../../constants";
import PhoneIcon from "../../icons/PhoneIcon";
import ArrowShewron from "../../icons/ArrowShewron";
import ManufacturerContentSection from "../../components/ManufacturerContentSection";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
    name: "PreviewManufacturer",
    components: {
        PhoneIcon,
        ArrowShewron,
        ManufacturerContentSection
    },
    data() {
        return {
            TYPE_FIELDS,
            showContent: null,
            buttonContent: null
        }
    },
    computed: {
        ...mapGetters({
            manufacturerItem: "manufacturer/manufacturerItem",
            isLoading: "manufacturer/isLoadingCreate",
            deviceId: "analytics/getDeviceId"
        }),
        saveVisitorActions() {
            return !this.$route?.query?.mode;
        }
    },
    methods: {
        ...mapActions({
            getManufactureItemsByCode: "manufacturer/getManufactureItemsByCode",
            getButtonContent: "manufacturer/getButtonContent",
            saveAnalyticAction: "analytics/SAVE_ACTION"
        }),
        saveAction(action) {
            let formData = {
                device_id: this.deviceId,
                entity_id: this.manufacturerItem?.id,
                entity_type: ACTION_ENTITY_TYPES.MANUFACTURER_ITEM,
                action_type: ACTION_TYPES.VIEW,
                ...action
            };
            this.saveAnalyticAction(formData);
        },
        getManufacturerItems(serialNumber) {
            this.getManufactureItemsByCode(serialNumber).then(res => {
                if (this.saveVisitorActions){
                    this.saveAction(res.id);
                }
            });
        },

        btnHandler(item) {
            if (item.type === TYPE_FIELDS.link) {
                window.open(item.data?.link, '_blank')
                if (this.saveVisitorActions){
                    this.saveAction({action_type: ACTION_TYPES.CLICK, entity_id: item.id, entity_type: ACTION_ENTITY_TYPES.MANUFACTURER_ITEM_BTN})
                }
            } else if (item.type === TYPE_FIELDS.phone) {
                window.open(`tel:${item.data?.phone_number}`, '_parent')
                if (this.saveVisitorActions){
                    this.saveAction({action_type: ACTION_TYPES.CLICK, entity_id: item.id, entity_type: ACTION_ENTITY_TYPES.MANUFACTURER_ITEM_BTN})
                }
            } else if (item?.type === TYPE_FIELDS.contentButton) {
                const val = this.showContent?.id === item?.id ? null : item;
                this.showContent = val;
                if (val) {
                    this.getButtonContent(item.id).then(res => {
                        this.buttonContent = res;
                    })
                    if (this.saveVisitorActions){
                        this.saveAction({action_type: ACTION_TYPES.CLICK, entity_id: item.id, entity_type: ACTION_ENTITY_TYPES.MANUFACTURER_ITEM_BTN})
                    }
                }else{
                    this.buttonContent = null;
                }
            }
        },
        async setDevice() {
            const fpPromise = FingerprintJS.load();
            const fp = await fpPromise;
            const result = await fp.get();

            if (this.saveVisitorActions) {
                this.$store.dispatch('analytics/SET_DEVICE_ID', result.visitorId);
            }
        }
    },
    async created() {
        const serialNumber = this.$route.params?.serial_number;
        if (serialNumber) this.getManufacturerItems(serialNumber);
        if (this.saveVisitorActions) this.setDevice();
    }
}
</script>

<style scoped>

</style>
